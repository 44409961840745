<template>
  <div>
    <div class="login-mobile-wrapper md:login-wrapper w-screen h-screen">
      <div class="grid md:flex">
        <div class="md:w-full md:h-screen">
          <div class="grid h-full">
            <div class="flex flex-col justify-center items-center">
              <img :src="loginContent.image" class="w-1/2 hidden md:block" />
              <p
                class="text-black text-lg md:text-4xl font-semibold mt-32 md:mt-4 font-Poppins tracking-wider text-center mx-6 md:mx-20"
              >
                {{ loginContent.title }}
              </p>
              <p
                class="text-black md:text-lg font-Poppins tracking-wider text-center mt-4 mx-6 md:mx-32"
              >
                {{ loginContent.sub_title }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="md:w-full md:h-screen md:grid md:justify-center md:items-center"
        >
          <div class="md:login-box-wrapper w-full flex justify-center">
            <div class="login-box-mobile md:login-box1">
              <LoginForm
                ref="loginForm"
                @showOfficialModal="showOfficialModal()"
                :loginType="loginType"
              />
            </div>
            <div class="md:login-box2"></div>
          </div>
          <div
            class="w-full font-semibold hidden md:flex text-black justify-center"
            v-if="companyLogo"
          >
            Powered by
            <img
              class="inline-block w-20 md:w-28 ml-1"
              :src="require('@/assets/images/logo.svg')"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      @proceed="onSubmitOfficial()"
      @closeModal="modal.show = $event"
      :modal="modal"
    ></ConfirmationModal>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import TokenService from "@/common/token.service";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";

export default {
  name: "Login",
  data() {
    return {
      companyLogo: localStorage.getItem("company_logo"),
      modal: {
        show: false,
        heading: "Official Email Address Needed",
        showLottie: true,
        lottiePath: "lotties/are-you-sure.json",
        question:
          "You will need your official email address to view the experiences. Do you still want to proceed?",
        action: "proceed",
      },
    };
  },
  components: { LoginForm, ConfirmationModal },
  methods: {
    showOfficialModal() {
      this.modal.show = true;
    },
    onSubmitOfficial() {
      this.$refs.loginForm.onSubmitOfficial();
    },
  },
  computed: {
    loginType() {
      var loginType = "generic";
      if (TokenService.getToken("usersrc")) {
        loginType = TokenService.getToken("usersrc");
      }
      return loginType;
    },
    loginContent() {
      var details = {};
      if (this.loginType == "generic") {
        details["image"] = require("@/assets/images/login/content-generic.svg");
        details["title"] = "WELCOME TO THE WORLD OF ONBOARDING EXPERIENCES.";
        details["sub_title"] = "We hope you are enjoying your journey so far!";
      } else if (this.loginType == "candidate") {
        details[
          "image"
        ] = require("@/assets/images/login/content-candidate.svg");
        details["title"] = "CONGRATULATIONS ON ACCEPTING THE OFFER!";
        details["sub_title"] =
          "We're looking forward to have you amongst us real soon!";
      } else if (this.loginType == "fdaw") {
        details["image"] = require("@/assets/images/login/content-fdaw.svg");
        details["title"] = "IT'S YOUR FIRST DAY!";
        details["sub_title"] =
          "We're as excited as you are to be here! Sit back, relax, learn and enjoy the journey with us.";
      } else if (this.loginType == "employee") {
        details[
          "image"
        ] = require("@/assets/images/login/content-employee.svg");
        details["title"] = "YOU ARE A PART OF US NOW.";
        details["sub_title"] =
          "We're thrilled to have you. We hope you enjoy a great time here.";
      } else if (this.loginType == "pulse") {
        details["image"] = require("@/assets/images/login/content-pulse.svg");
        details["title"] = "HOW HAS YOUR JOURNEY BEEN?";
        details["sub_title"] =
          "Lets sit back and catch up on how your journey has been with us. so far!";
      }

      return details;
    },
  },
};
</script>

<style lang="postcss" scoped>
.login-mobile-wrapper {
  background-image: url("~@/assets/images/login/login-bg-mobile.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.login-box-mobile {
  width: 100%;
  height: 100%;
  @apply bg-white border border-black rounded-xl mx-4 my-4;
}
.login-box-wrapper-mobile {
  min-height: 28rem;
}
@responsive {
  .login-box-wrapper {
    min-height: 28rem;
  }
  .login-wrapper {
    background-image: url("~@/assets/images/login/login-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .login-box1 {
    width: 26rem;
    height: 28rem;
    @apply bg-white border border-black rounded-xl absolute -top-24 transform z-20;
    --tw-translate-y: 50% !important;
  }
  .login-box2 {
    width: 26rem;
    height: 28rem;
    @apply bg-white border border-black rounded-xl absolute -top-24 transform -rotate-3 z-10;
    --tw-translate-y: 50% !important;
  }
}
@media (min-width: 1367px) {
  .login-box1 {
    width: 26rem;
    height: 28rem;
    top: -2.5rem;
  }
  .login-box2 {
    width: 26rem;
    height: 28rem;
    top: -2.5rem;
  }
}
</style>
