<template>
  <div>
    <div class="w-full grid justify-center items-center py-4">
      <div class="logo-title flex justify-center md:mt-4" v-if="companyLogo">
        <img class="w-32" :src="companyLogo" alt="" v-if="companyLogo" />
        <img
          class="w-40"
          :src="require('@/assets/images/logo.svg')"
          alt=""
          v-else
        />
      </div>
      <h2 class="text-xl md:text-2xl font-semibold text-black text-center">
        Onboarding Experiences
      </h2>
      <form
        v-if="invalidEmail"
        @submit.prevent="onSubmitEmail"
        novalidate
        class="md:px-8"
      >
        <div class="email mt-4 md:mt-0">
          <div class="f-outline relative mt-4 md:mt-8 block">
            <input
              type="text"
              name="email"
              ref="email"
              id="email"
              autocomplete="on"
              v-model="email"
              class="textInputLogin w-80"
              autofocus
              required
            />
            <span class="floating-label">Email address</span>
            <div class="absolute top-4 right-7 bg-white">
              <img
                class="h-4"
                :src="require('@/assets/images/login/icon-email.svg')"
                alt="icon-email"
              />
            </div>
          </div>
          <p class="p-2 flex text-xs text-gray-500" v-if="loginType == 'fdaw'">
            * Company email id needed to view the experiences.
          </p>
        </div>
        <div
          class="text-sm text-red-500 mt-3"
          v-if="v$.email.$invalid && checkValidation"
        >
          {{ v$.email.$silentErrors[0].$message }}
        </div>
        <div class="message pt-2">
          <div v-if="errorMsg" class="error text-red-600">
            <p class="text-sm">{{ errorMsg }}</p>
          </div>
          <div v-if="successMsg" class="success text-green-700 ml-14">
            <p class="text-sm">
              {{ successMsg }}
              <a
                class="text-green-900 underline italic"
                :href="link"
                target="_blank"
                >{{ link }}</a
              >
            </p>
          </div>
        </div>
        <div class="submit my-3 md:pb-20">
          <ButtonInput
            :buttonText="'get otp'"
            :loading="loading"
            buttonClass="getotp-desktop-button"
            loadingClass="getotp-desktop-loader"
            fillColor="#CBB076"
          />
        </div>
      </form>
      <form v-else @submit.prevent="onSubmit" novalidate class="md:px-8">
        <div class="email">
          <div class="f-outline relative mt-8">
            <input
              type="text"
              name="email"
              ref="email"
              id="email"
              autocomplete="on"
              v-model="email"
              class="textInputLogin block appearance-none focus:outline-none bg-transparent w-80"
              autofocus
              required
            />
            <span class="floating-label">Email address</span>
            <div class="absolute top-3.5 right-7 bg-white">
              <div
                @click="reloadPage"
                class="block font-medium text-right text-nLoginMobileTextButton"
              >
                Change
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-sm text-red-500 mt-3"
          v-if="v$.email.$invalid && checkValidation"
        >
          {{ v$.email.$silentErrors[0].$message }}
        </div>
        <div
          v-if="!invalidEmail"
          class="otp mt-3 transition duration-300 ease-out"
          :class="{ hidden: invalidEmail }"
        >
          <div class="f-outline relative mt-8">
            <input
              type="password"
              name="otp"
              id="otp"
              ref="otp"
              v-model="otp"
              class="textInputLogin block appearance-none focus:outline-none bg-transparent w-80"
              autofocus
              required
            />
            <span class="floating-label">OTP</span>
            <div
              class="absolute top-5 right-8 bg-white cursor-pointer"
              @click="toggleOTPVisibility()"
            >
              <img
                id="otpOpenClose"
                class="w-6"
                :src="require('@/assets/images/login/icon-otp-close.png')"
                alt="icon-email"
              />
            </div>
          </div>
          <div
            class="text-sm text-red-500 mt-3"
            v-if="v$.otp.$invalid && checkValidation"
          >
            {{ v$.otp.$silentErrors[0].$message }}
          </div>
        </div>
        <div
          class="flex gap-1 place-content-end md:place-content-center items-center mt-2"
        >
          <span class="text-black">Didn't receive OTP?</span>
          <div
            @click.prevent="resendOtp()"
            class="block font-medium text-right text-nLoginMobileTextButton"
          >
            Resend OTP
          </div>
        </div>
        <div class="message text-sm inline-block mt-4 h-4">
          <div v-if="errorMsg" class="error text-red-600 rounded">
            <p class="text-sm">{{ errorMsg }}</p>
          </div>
          <div v-if="successMsg" class="success text-green-700">
            <p class="text-sm">
              {{ successMsg }}
            </p>
          </div>
          <div class="otp" v-if="resend.status">
            <p class="text-yellow-500">
              {{ resend.message }}
            </p>
          </div>
        </div>
        <div class="submit">
          <ButtonInput
            :buttonText="'login'"
            :loading="loading"
            buttonClass="login-desktop-button"
            loadingClass="login-desktop-loader"
            fillColor="#CBB076"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import { messages } from "@/common/messages.js";

export default {
  name: "LoginForm",
  props: ["loginType"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: null,
      otp: null,
      invalidEmail: true,
      ignoreOfficial: false,
      loading: false,
      errorMsg: null,
      successMsg: null,
      checkValidation: false,
      resend: {
        status: null,
        message: null,
      },
      companyLogo: localStorage.getItem("company_logo"),
      messages,
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(messages.login.email.required, required),
        email: helpers.withMessage(messages.login.email.invalid, email),
      },
      otp: {
        required: helpers.withMessage(messages.login.otp.required, required),
      },
    };
  },
  components: {
    ButtonInput,
  },
  methods: {
    onSubmitOfficial() {
      this.ignoreOfficial = true;
      this.onSubmitEmail();
    },
    onSubmit() {
      this.checkValidation = true;
      if (!this.v$.$invalid) {
        this.loading = true;
        this.$store
          .dispatch("validateOtp", {
            email: this.email,
            otp: this.otp,
          })
          .then((data) => {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push({
                name: "UserHome",
                params: { company: data.company },
              });
            }
            this.loading = false;
          })
          .catch(({ error }) => {
            this.successMsg = null;
            this.loading = false;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
        return;
      }
    },

    onSubmitEmail() {
      this.checkValidation = true;
      this.loading = true;
      if (!this.v$.email.$invalid) {
        ApiService.post(apiResource.auth.validateEmail, {
          email: this.email,
          ignore_official: this.ignoreOfficial,
        })
          .then(() => {
            this.loading = false;
            this.invalidEmail = false;
            this.errorMsg = null;
            this.checkValidation = false;
            this.$nextTick(() => {
              this.$refs.otp.focus();
            });
          })
          .catch(({ response }) => {
            this.loading = false;
            this.successMsg = null;
            if (response.data.msg == "official_needed") {
              this.$emit("showOfficialModal");
            } else {
              this.errorMsg = response.data.msg;
            }
          });
      } else {
        this.loading = false;
      }
    },
    reloadPage() {
      window.location.reload();
    },
    toggleOTPVisibility() {
      var otpTextbox = document.getElementById("otp");
      var otpIcon = document.getElementById("otpOpenClose");
      if (otpTextbox.type === "password") {
        otpTextbox.type = "text";
        otpIcon.src = require("@/assets/images/login/icon-otp-open.png");
      } else {
        otpTextbox.type = "password";
        otpIcon.src = require("@/assets/images/login/icon-otp-close.png");
      }
    },
    toggleOTPMobileVisibility() {
      var otpTextbox = document.getElementById("otpMobile");
      var otpIcon = document.getElementById("otpOpenClose");
      if (otpTextbox.type === "password") {
        otpTextbox.type = "text";
        otpIcon.src = require("@/assets/images/login/icon-mobile-eye-open.svg");
      } else {
        otpTextbox.type = "password";
        otpIcon.src = require("@/assets/images/login/icon-mobile-eye-close.svg");
      }
    },
    resendOtp() {
      ApiService.post(apiResource.auth.resendOtp, {
        email: this.email,
      }).then((data) => {
        this.resend.status = true;
        this.resend.message = data.data.msg;
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="postcss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}
input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  background: white;
  top: -11px;
  bottom: 10px;
  left: 20px;
  opacity: 1;
  height: 20px;
}

.floating-label {
  color: black;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 15px;
  transition: 0.2s ease all;
}
.textInputLogin {
  @apply bg-white rounded-lg text-black px-3 pt-3 pb-4 shadow border border-gray-600 focus:outline-none focus:border-gray-500;
}
.f-outline input:focus-within ~ label,
.f-outline input:not(:placeholder-shown) ~ label {
  @apply font-semibold;
  transform: translateY(-1.6rem) translatex(-0.5rem) scaleX(0.8) scaleY(0.8);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.image-content-text-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  padding: 20px 40px;
}
.image-content-mobile-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 20px 40px;
}
.highlighted-text {
  color: #f6b201;
}
.getotp-desktop-button {
  @apply shadow relative transition duration-300 ease-out tracking-wider text-xl w-full md:w-48 lg:w-80 py-1.5 rounded-lg hover:shadow-lg uppercase font-medium mr-4;
  border: 1px solid #cbb076;
  color: #cbb076;
}
.getotp-desktop-button:hover {
  background: #cbb076;
  color: #ffffff;
}
.getotp-desktop-loader {
  @apply cursor-not-allowed shadow relative transition duration-300 ease-out tracking-wider w-full md:w-48 lg:w-80 py-1.5 rounded-lg uppercase text-xl font-medium mr-4;
  border: 1px solid #cbb076;
  color: #cbb076;
}
.login-desktop-button {
  @apply shadow relative transition duration-300 ease-out text-white tracking-wider text-xl w-full md:w-48 lg:w-80 py-1.5 rounded-lg hover:shadow-lg uppercase font-medium mr-4;
  border: 1px solid #cbb076;
  color: #cbb076;
}
.login-desktop-button:hover {
  background: #cbb076;
  color: #ffffff;
}
.login-desktop-loader {
  @apply cursor-not-allowed shadow relative transition duration-300 ease-out text-white tracking-wider w-full md:w-48 lg:w-80 py-1.5 rounded-lg uppercase text-xl font-medium mr-4;
}
</style>
