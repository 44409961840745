export const messages = {
  login: {
    email: {
      required: "Enter Email",
      invalid: "Invalid Email",
    },

    otp: {
      required: "Enter OTP",
      invalid: "Invalid OTP",
    },
  },
};
